<template>
  <base-section
    id="theme-features"
    class="spacing-playground pa-8"
  >
    <base-section-heading
      title="GENERAL INFORMATION"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            {{ text }}
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      text: '',
      features: [
        {
          color: '#483d8b',
          title: 'Contact Us',
          icon: 'mdi-cellphone',
          text: 'Please feel free to contact us by using one of the methods below.',
        },
        {
          color: '#e97770',
          title: 'Maintenance',
          icon: 'mdi-wrench-outline',
          text: "We take care of the maintenance so you don't have to. We all have so much to worry about these days your house should not be one of them.",
        },
        {
          color: '#483d8b',
          dark: true,
          title: 'Feedback',
          icon: 'mdi-clipboard-account-outline',
          text: 'Please leave feedback on how we are doing by using the form below. We want to make your life better in any way that we can.',
        },
        {
          color: '#e97770',
          title: 'Flexible leases',
          icon: 'mdi-file-document-edit-outline',
          text: 'We provide flexible leases depending on your needs. We understand that everyone has different needs based on their lifestyle. Please let us accomodate you.',
        },
      ],
    }),
  }
</script>
